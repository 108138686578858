import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { LayoutStoreService } from '@shared/layout/layout-store.service';
import { CopyToClipboardDirective } from '@shared/directives/copy-to-clipboard.directive';
import { SortingHeaderComponent } from '@shared/components/sorting/sorting-link/sorting-header.component';
import { SearchbarFiltersComponent } from '@shared/searchbar-filters/searchbar-filters.component';
import { FormsModule } from '@node_modules/@angular/forms';

@NgModule({
    imports: [CommonModule, RouterModule, NgxPaginationModule, FormsModule],
    declarations: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        SearchbarFiltersComponent,
    ],
    exports: [
        AbpPaginationControlsComponent,
        CopyToClipboardDirective,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        SortingHeaderComponent,
        SearchbarFiltersComponent,
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppSessionService, AppUrlService, AppAuthService, AppRouteGuard, LayoutStoreService],
        };
    }
}
